<template>
  <div class="animationOpacity" :class="{'spaceComunidade':!isMobile, 'spaceComunidadeMobile':isMobile}">
    <div class="headerFlex">
      <div class="title">
        Comunidade
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 17H5.01M5 5H5.01M3 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H3C1.89543 21 1 20.1046 1 19V15C1 13.8954 1.89543 13 3 13ZM3 1H19C20.1046 1 21 1.89543 21 3V7C21 8.10457 20.1046 9 19 9H3C1.89543 9 1 8.10457 1 7V3C1 1.89543 1.89543 1 3 1Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="spaceSearch">
      <div class="inputSearch">
        <img
            src="@/assets/icons/search.svg"
            @click="getAllComments()"
            class="Pointer search"
        />
        <b-form-input
            class="input-busca"
            @change="getAllComments()"
            v-model="searchComment"
            placeholder="Pesquise um comentario"
        ></b-form-input>
    </div>
    </div>
    <div :class="{'gridListMenu':!isMobile,'gridListMenuMobile':isMobile}">
      <div class="menu" @click="getList('')" :class="{'activeList':selectedList === ''}">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 21V13H7V21" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 3V8H15" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ({{CountComments.todos}})
        Todos</div>
      <div class="menu" @click="getList('draft')" :class="{'activeList':selectedList === 'draft'}">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 9V12L13.5 13.5" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.51 17.35L16.16 21.18C16.1149 21.6786 15.8845 22.1423 15.5142 22.4792C15.1439 22.8162 14.6607 23.002 14.16 23H9.83C9.32932 23.002 8.8461 22.8162 8.4758 22.4792C8.1055 22.1423 7.87505 21.6786 7.83 21.18L7.48 17.35M7.49 6.65L7.84 2.82C7.8849 2.32308 8.11393 1.86088 8.48211 1.52415C8.8503 1.18742 9.33105 1.00047 9.83 0.999999H14.18C14.6807 0.997968 15.1639 1.1838 15.5342 1.52077C15.9045 1.85775 16.1349 2.32136 16.18 2.82L16.53 6.65" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ({{CountComments.pendentes}})
        Pendentes</div>
      <div class="menu" @click="getList('published')" :class="{'activeList':selectedList === 'published'}">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V13C2 12.4696 2.21071 11.9609 2.58579 11.5858C2.96086 11.2107 3.46957 11 4 11H7M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L7 11V22H18.28C18.7623 22.0055 19.2304 21.8364 19.5979 21.524C19.9654 21.2116 20.2077 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6842 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.90629 21.1919 9.68751C21.0016 9.46873 20.7661 9.29393 20.5016 9.17522C20.2371 9.0565 19.9499 8.99672 19.66 9H14Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ({{CountComments.aprovados}})
        Aprovados</div>
      <div class="menu" @click="getList('reproved')" :class="{'activeList':selectedList === 'reproved'}">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 2H19.67C20.236 1.98999 20.7859 2.18813 21.2154 2.55681C21.6449 2.9255 21.9241 3.43905 22 4V11C21.9241 11.5609 21.6449 12.0745 21.2154 12.4432C20.7859 12.8119 20.236 13.01 19.67 13H17M10 15V19C10 19.7956 10.3161 20.5587 10.8787 21.1213C11.4413 21.6839 12.2044 22 13 22L17 13V2H5.72C5.23767 1.99454 4.76962 2.16359 4.40209 2.47599C4.03457 2.78839 3.79232 3.22309 3.72 3.7L2.34 12.7C2.29649 12.9866 2.31583 13.2793 2.39666 13.5577C2.47749 13.8362 2.6179 14.0937 2.80814 14.3125C2.99839 14.5313 3.23392 14.7061 3.49843 14.8248C3.76294 14.9435 4.05009 15.0033 4.34 15H10Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ({{CountComments.reprovados}})
        Reprovados</div>
      <div class="menu" @click="getList('trashed')" :class="{'activeList':selectedList === 'trashed'}">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15 9L9 15" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 9L15 15" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ({{CountComments.lixeira}})
        Lixeira</div>
    </div>

    <div v-if="btnsActions" class="animations-btn-action">
      <div class="flex">
        <button class="btns-actions animations-btn-action" v-on:click="actionEditar('aprovar')">
            <svg class="espacamento-svg-action" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7022 0.289387C12.6162 0.197689 12.5139 0.124912 12.4012 0.0752425C12.2885 0.0255733 12.1676 0 12.0455 0C11.9234 0 11.8025 0.0255733 11.6897 0.0752425C11.577 0.124912 11.4747 0.197689 11.3887 0.289387L4.49736 7.58766L1.60206 4.51574C1.51277 4.42447 1.40738 4.35275 1.29188 4.30462C1.17639 4.25649 1.05306 4.23291 0.92894 4.23516C0.804822 4.23741 0.68234 4.26559 0.568489 4.31793C0.454648 4.37017 0.351656 4.44579 0.265408 4.5402C0.179159 4.63461 0.111347 4.74604 0.0658268 4.86823C0.020316 4.99042 -0.00200475 5.12083 0.000141283 5.25212C0.00228732 5.38331 0.0288629 5.51294 0.078342 5.63328C0.12783 5.75371 0.199251 5.86259 0.288533 5.95387L3.84059 9.71061C3.92658 9.80228 4.0289 9.87507 4.14161 9.92477C4.25434 9.97437 4.37524 10 4.49736 10C4.61947 10 4.74035 9.97437 4.85311 9.92477C4.96577 9.87507 5.06808 9.80228 5.15411 9.71061L12.7022 1.72752C12.7961 1.63591 12.8711 1.52472 12.9223 1.40096C12.9735 1.27721 13 1.14356 13 1.00845C13 0.873345 12.9735 0.739707 12.9223 0.615949C12.8711 0.492192 12.7961 0.381006 12.7022 0.289387Z" fill="#00E4A0"/>
            </svg>
            Aprovar
        </button>
        <button class="btns-actions animations-btn-action" v-on:click="actionEditar('reprovar')">
            <svg class="espacamento-svg-action" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.17385 4.99927L9.75374 1.42724C9.9105 1.27045 9.99859 1.05779 9.99859 0.836055C9.99859 0.614321 9.9105 0.40167 9.75374 0.244874C9.59697 0.0880859 9.38434 0 9.16264 0C8.94094 0 8.72831 0.0880859 8.57154 0.244874L4.99998 3.82524L1.42846 0.244874C1.27169 0.0880859 1.05906 0 0.83736 0C0.615657 0 0.403028 0.0880859 0.246263 0.244874C0.0894969 0.40167 0.00142358 0.614321 0.00142358 0.836055C0.00142358 1.05779 0.0894969 1.27045 0.246263 1.42724L3.82611 4.99927L0.246263 8.57134C0.168229 8.64878 0.106298 8.74087 0.06403 8.84237C0.0217624 8.94378 0 9.05261 0 9.16252C0 9.27252 0.0217624 9.38134 0.06403 9.48276C0.106298 9.58426 0.168229 9.67635 0.246263 9.7537C0.323655 9.83181 0.415733 9.89375 0.517185 9.93597C0.618637 9.97827 0.727457 10 0.83736 10C0.947262 10 1.05608 9.97827 1.15754 9.93597C1.25899 9.89375 1.35107 9.83181 1.42846 9.7537L4.99998 6.17331L8.57154 9.7537C8.64897 9.83181 8.74105 9.89375 8.84253 9.93597C8.94394 9.97827 9.05275 10 9.16264 10C9.27262 10 9.38143 9.97827 9.48283 9.93597C9.58432 9.89375 9.6764 9.83181 9.75374 9.7537C9.83183 9.67635 9.89377 9.58426 9.93598 9.48276C9.97827 9.38134 10 9.27252 10 9.16252C10 9.05261 9.97827 8.94378 9.93598 8.84237C9.89377 8.74087 9.83183 8.64878 9.75374 8.57134L6.17385 4.99927Z" fill="#00E4A0"/>
            </svg>
            Reprovar
        </button>
      </div>
      <div class="textTotalSelected">Total Selecionado: {{ status_auto.length }}</div>
    </div>
    <div class="spaceTable animationOpacity2">
        <b-table :fields="fieldsx" show-empty small stacked="md" :items="AllComments" :tbody-tr-class="rowClass" :filter-included-fields="filterOn" v-if="!isMobile">
          <template #empty>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>
          <template #emptyfiltered>
            <p class="title text-center-empty">{{ emptyText }}</p>
          </template>

          <template v-slot:head(id)>
              <label class="check" v-if="!isMobile">
                  <b-form-checkbox-group  
                      id="checkbox-group-all" 
                      v-model="status_auto2" 
                      @change="clickCheckAll"
                      class="input-check"
                      name="flavour-all"
                  >
                      <b-form-checkbox value="xx"></b-form-checkbox>
                  </b-form-checkbox-group>
              </label>
          </template>

          <template #cell(id)="row">
              <div v-if="!isMobile">
                  <b-form-checkbox-group  
                      :id="`checkbox-group-`+row.item.id" 
                      v-model="status_auto" 
                      @change="clickCheck"
                      class="input-check"
                      :name="`flavour-`+row.item.id"
                  >
                      <b-form-checkbox :value="row.item.id"></b-form-checkbox>
                  </b-form-checkbox-group>
              </div>
          </template>

          <template #cell(member)="row">
            <div class="spaceCreate animationOpacity2">
              <div class="dataCreate">
                <div class="userPic">
                  <img v-if="row.item.member.picture === null || row.item.member.picture === undefined || row.item.member.picture === ''" src="@/assets/icons/pic-profile.svg" />
                  <img v-else :src="row.item.member.picture" />
                </div>
                <div class="userDados">
                  <div class="name">{{ row.item.member.name }}</div>
                  <div class="email">{{ row.item.member.email }}</div>
                </div>
              </div>
              <div class="date">{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</div>
            </div>
          </template>

          <template #cell(comment)="row">
              <div class="spaceComment animationOpacity2">
                <div class="comment">{{ row.item.comment }}</div>
                <div class="actions">
                  <button class="btns resp" v-if="selectedList === 'published' && row.item.liked === false" @click="likeComent(row.item)"><span class="numberLiked">{{  row.item.likes_count }}</span>Curtir</button>
                  <button class="btns resp" v-if="selectedList === 'published' && row.item.liked === true" @click="removeLikeComent(row.item)"><span class="numberLiked">{{  row.item.likes_count }}</span>Descurtir</button>
                  <button class="btns aprove" v-if="(row.item.status === 'draft' || row.item.status === 'reproved') && selectedList !== 'trashed'" @click="aprovComment(row.item.id)">Aprovar</button>
                  <button class="btns removeaprove" v-if="row.item.status === 'publish' && selectedList !== 'trashed'" @click="removeAprovComment(row.item.id)">Remover Aprovação</button>
                  <button class="btns resp" v-if="selectedList !== 'trashed' && row.item.status !== 'reproved'" @click="respComment(row.item.id), row.item.toEdit = true">Responder</button>
                  <button class="btns delete" v-if="row.item.status !== 'reproved' && selectedList !== 'trashed'" @click="ReprovComment(row.item.id)">Reprovar</button>
                  <button class="btns delete"  v-if="row.item.status === 'reproved' && selectedList !== 'trashed'" @click="deleteComment(row.item.id)">Deletar</button>
                  <button class="btns delete"  v-if="selectedList === 'trashed'" @click="restauraComment(row.item.id)">Restaurar</button>
                </div>
                <div v-if="row.item.toEdit">
                  <textarea
                    type="text"
                    id="myComment"
                    class="animationOpacity"
                    placeholder="Escreva sua resposta"
                    v-model="minhaResposta"
                    v-on:keyup.enter="responderComentario(row.item)"
                  ></textarea>
                    <button class="btnResp cancelaBtn animationOpacity2" @click.prevent="row.item.toEdit = false">
                      Cancelar
                    </button>
                    <button class="btnResp animationOpacity2" @click.prevent="responderComentario(row.item)">
                      Responder
                    </button>
                </div>
              </div>
          </template>

          <template #cell(lesson)="row">
              <div class="spaceInfo animationOpacity2" @click="moveToLesson(row.item)">
                <div class="info">{{ row.item.lesson.title }}</div>
                <div class="info">{{ row.item.lesson.module.title }}</div>
                <div class="info">{{ row.item.lesson.module.course.title }}</div>
              </div>
          </template>
      </b-table>

      <b-table :fields="fieldsxMobile" show-empty small :items="AllComments" :tbody-tr-class="rowClass" :filter-included-fields="filterOn" v-if="isMobile">
          <template #empty>
              <p class="title text-center-empty">{{ emptyText }}</p>
              </template>
              <template #emptyfiltered>
              <p class="title text-center-empty">{{ emptyText }}</p>
          </template>

          <template #cell(member)="row">
            <div class="spaceCreate animationOpacity2">
              <div class="dataCreate">
                <div class="userDados">
                  <div class="name">{{ row.item.member.name }}</div>
                  <!-- <div class="email">{{ row.item.member.email }}</div> -->
                  <div class="email">{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</div>
                </div>
              </div>
            </div>
          </template>

          <template #cell(comment)="row">
              <div class="spaceComment animationOpacity2">
                <div class="comment">{{ row.item.comment }}</div>
              </div>
          </template>

          <template #cell(lesson)="row">
              <div class="spaceComment animationOpacity2">
                <div class="actions">
                  <button class="btns resp" v-if="selectedList === 'published' && row.item.liked === false" @click="likeComent(row.item)"><span class="numberLiked">{{  row.item.likes_count }}</span>Curtir</button>
                  <button class="btns resp" v-if="selectedList === 'published' && row.item.liked === true" @click="removeLikeComent(row.item)"><span class="numberLiked">{{  row.item.likes_count }}</span>Descurtir</button>
                  <button class="btns aprove" v-if="(row.item.status === 'draft' || row.item.status === 'reproved') && selectedList !== 'trashed'" @click="aprovComment(row.item.id)">Aprovar</button>
                  <button class="btns removeaprove" v-if="row.item.status === 'publish' && selectedList !== 'trashed'" @click="removeAprovComment(row.item.id)">Remover Aprovação</button>
                  <button class="btns resp" v-if="selectedList !== 'trashed' && row.item.status !== 'reproved'" @click="moveToLesson(row.item)">Responder</button>
                  <button class="btns delete" v-if="row.item.status !== 'reproved' && selectedList !== 'trashed'" @click="ReprovComment(row.item.id)">Reprovar</button>
                  <button class="btns delete"  v-if="row.item.status === 'reproved' && selectedList !== 'trashed'" @click="deleteComment(row.item.id)">Deletar</button>
                  <button class="btns delete"  v-if="selectedList === 'trashed'" @click="restauraComment(row.item.id)">Restaurar</button>
                </div>
                <div v-if="row.item.toEdit">
                  <textarea
                    type="text"
                    id="myComment"
                    class="animationOpacity"
                    placeholder="Escreva sua resposta"
                    v-model="minhaResposta"
                    v-on:keyup.enter="responderComentario(row.item)"
                  ></textarea>
                    <button class="btnResp cancelaBtn animationOpacity2" @click.prevent="row.item.toEdit = false">
                      Cancelar
                    </button>
                    <button class="btnResp animationOpacity2" @click.prevent="responderComentario(row.item)">
                      Responder
                    </button>
                </div>
              </div>
          </template>
      </b-table>

      <div class="paginate">
        <div class="text" @click="toPage('prev')">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 6H1M1 6L6 11M1 6L6 1" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Anterior
        </div>
        <div class="text" @click="toPage('next')">
          Próximo
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6L11 6M11 6L6 1M11 6L6 11" stroke="#333333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div> 
  </div>
  </div>
</template>

<script>
import CommentService from "@/services/resources/CommentService";
const serviceComments = CommentService.build();
import CourseService from "@/services/resources/CourseService";
const serviceCource = CourseService.build();

export default {
  components:{
  },
  data() {
    return {
      moderation:true,
      rowClass:'row-table-body',
      emptyText: "Sem Resultados",
      filterOn: [],
      AllComments:[
      ],
      fieldsx: [
          { key: 'id', label: ''},
          { key: 'member', label: 'Criado por'},
          { key: 'comment', label: 'Comentário'},
          { key: 'lesson', label: 'Em Resposta à'},
      ],
      fieldsxMobile: [
          { key: 'member', label: 'Autor'},
          { key: 'comment', label: 'Comentário'},
          { key: 'lesson', label: 'Ações'},
      ],
      pageActual:1,
      lastPage:1,
      firstPage:1,
      openRespId:"",
      minhaResposta:'',
      textAreaResp:'',
      selectedList:'draft',
      searchComment:'',
      status_auto: [],
      status_auto2: [],
      btnsActions: false,
      btnsActionsEditar: true,
      CountComments:[],
      client:{
        width: 0
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
      this.handleResize();
  },
  methods: {
    removeLikeComent(dados){
      var data = {
        id: dados.lesson.module.course.id+'/module/'+dados.lesson.module.id+'/lesson/'+dados.lesson.id+'/comment/'+dados.id+'/like',
      }
      this.$root.$emit('loadOn');
      serviceCource
        .destroy(data)
        .then((resp) => {
          //console.log("deslike comment", resp)
          this.getAllComments();
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    likeComent(dados){
      var data = {
        id: dados.lesson.module.course.id+'/module/'+dados.lesson.module.id+'/lesson/'+dados.lesson.id+'/comment/'+dados.id+'/like',
      }
      this.$root.$emit('loadOn');
      serviceCource
        .updateId(data)
        .then((resp) => {
          //console.log("like comment", resp);
          this.getAllComments();
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    handleResize() {
        this.client.width = window.innerWidth;
    },
    actionEditar(action){
      //console.log(this.status_auto);
      let itens = this.status_auto;
      var data = '';
      if(action === 'reprovar'){
       for (let i = 0; i < itens.length; i++) {
          const element = itens[i];
            data = { id: element, status: 'reproved' } 
            this.$root.$emit('loadOn');
            serviceComments
            .postID(data)
            .then((resp) => {
              this.$root.$emit('loadOff');
              //console.log("reproved all comment", resp);
            })
            .catch((err) => {
              //console.log(err);
              this.$root.$emit('loadOff');
            });
        }
        this.getAllComments();
      }else{
        for (let i = 0; i < itens.length; i++) {
          const element = itens[i];
          //console.log(element);
            data = { id: element, status: 'published' } 
            this.$root.$emit('loadOn');
            serviceComments
            .postID(data)
            .then((resp) => {
              this.$root.$emit('loadOff');
              //console.log("aprov all comment", resp);
            })
            .catch((err) => {
              //console.log(err);
              this.$root.$emit('loadOff');
            });
        }
        this.getAllComments();
      }
    },
    clickCheck(){
        this.verificaBtn();
    },
    clickCheckAll(){
        if(this.status_auto.length === 0){
            let coments = this.AllComments;
            let array = [];
            for (let i = 0; i < coments.length; i++) {
                const element = coments[i].id;
                array.push(element);
            }
            this.status_auto = array;
            this.verificaBtn();
        }else{
            this.status_auto = [];
            this.status_auto2 = [];
            this.verificaBtn();
        }
    },
    verificaBtn(){
        if(this.status_auto.length !== 0){
            this.btnsActions = true;
        }else if(this.status_auto.length === 0){
            this.btnsActions = false;
        }
        this.verificaBtn2();
    },
    verificaBtn2(){
          if(this.status_auto.length === 1){
              this.btnsActionsEditar = true;
        }else if(this.status_auto.length !== 1){
            this.btnsActionsEditar = false;
        }
    },
    getList(data){
      this.selectedList = data;
      if(data === 'trashed'){
        this.getTrashed();
      }else{
        this.getAllComments();
      }
    },
    getTrashed() {
      this.$root.$emit('loadOn');
      this.pageActual = 1;
      var url = 'trash?page='+this.pageActual;
      serviceComments
        .read(url)
        .then((resp) => {
          //console.log("comments trash", resp);
          var data = resp.data;
          for (let i = 0; i < data.length; i++) {
            data[i].toEdit = false;
          }
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.AllComments = data;
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    toPage(action){
      if(action === 'prev'){
        if(this.pageActual !== 1){
          this.pageActual = this.pageActual-1;
        }
      }else{
        if(this.lastPage > this.pageActual){
          this.pageActual = this.pageActual+1;
        }
      }
      this.getAllComments();
    },
    responderComentario(row){
      var data = {
        id: `/${row.lesson.module.course.id}/module/${row.lesson.module.id}/lesson/${row.lesson.id}/comment`,
        comment:this.minhaResposta,
        parent_lesson_comment_id:row.id,
      }
      this.$root.$emit('loadOn');
      serviceCource
        .postID(data)
        .then((resp) => {
          //console.log("resposta comment", resp)
            this.minhaResposta = "";
            this.getAllComments();
            this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    respComment(id){
      this.openRespId = id;
      this.textAreaResp = true;
    },
    restauraComment(idComment){
      this.$root.$emit('loadOn');
      var data = { id: idComment+'/restore'} 
      serviceComments
        .postID(data)        
        .then((resp) => {
          this.$root.$emit('loadOff');
          //console.log("restaura comment", resp);
          this.getTrashed();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    deleteComment(idComment){
      this.$root.$emit('loadOn');
      serviceComments
        .destroy(idComment)        
        .then((resp) => {
          this.$root.$emit('loadOff');
          //console.log("update course status", resp);
          this.getAllComments();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    moveToLesson(row){
      let routeData = this.$router.resolve("/curso/"+row.lesson.module.course.id+"/modulo/"+row.lesson.module.id+"/aula/"+row.lesson.id);
      window.open(routeData.href, '_blank');
    },
    removeAprovComment(idComment){
      this.$root.$emit('loadOn');
      serviceComments
        .postID({ id: idComment, status: 'draft' })
        .then((resp) => {
          this.$root.$emit('loadOff');
          //console.log("update course status", resp);
          this.getAllComments();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    ReprovComment(idComment){
      this.$root.$emit('loadOn');
      serviceComments
        .postID({ id: idComment, status: 'reproved' })
        .then((resp) => {
          this.$root.$emit('loadOff');
          //console.log("reprov comment", resp);
          this.getAllComments();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    aprovComment(idComment){
      this.$root.$emit('loadOn');
      serviceComments
        .postID({ id: idComment, status: 'published' })
        .then((resp) => {
          this.$root.$emit('loadOff');
          //console.log("aprov comment", resp);
          this.getAllComments();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    getCountComments(){
      serviceComments
        .read('categories')
        .then((resp) => {
          //console.log("comments count", resp);
          this.CountComments = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getAllComments() {
      this.$root.$emit('loadOn');
      this.status_auto = [];
      this.status_auto2 = [];
      this.btnsActions = false;
      this.btnsActionsEditar = true;

      if(this.pageActual === null){
        this.pageActual = 1;
      }

      var url = ''
      if(this.searchComment === '' || this.searchComment === null){
          if(this.selectedList === ''){
          url = 'page='+this.pageActual;
        }else{
          url = 'page='+this.pageActual+'&status[]='+this.selectedList;
        }

      }else{
        if(this.selectedList === ''){
          url = 'page='+this.pageActual+'&search_string='+this.searchComment;
        }else{
          url = 'page='+this.pageActual+'&status[]='+this.selectedList+'&search_string='+this.searchComment;
        }
      }
      serviceComments
        .search(url)
        .then((resp) => {
          //console.log("all comments", resp);
          var data = resp.data;
          for (let i = 0; i < data.length; i++) {
            data[i].toEdit = false;
          }
          this.pageActual = resp.current_page;
          this.lastPage = resp.last_page;
          this.AllComments = data;
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
        this.getCountComments();
    },
  },
  mounted() {
    this.getAllComments();
  },
};
</script>

<style lang="scss">
.spaceComunidadeMobile {
  width: 100%;
  padding: 0 0 0 20px;
  padding-top: 70px;
  .table tr.row-table-body {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
  }
  .textTotalSelected{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858E;
    margin-bottom: -40px;
    margin-top: 20px;
  }
  .espacamento-svg-action{
      margin-right: 5px;
      margin-bottom: 3px;
  }
  .btns-actions{
      border:none;
      border: 1px solid #00E4A0;
      box-sizing: border-box;
      border-radius: 3px;
      height: 38px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #00E4A0;
      margin-right: 20px;
      background-color: #fff0;
      width: 136px;
      margin-top: 30px;
      margin-bottom: -40px;
  }
  .spaceSearch{
    .inputSearch {
        position: relative;
        margin-top: 20px;
        top:10px;
        img{
          cursor: pointer;
        }
    }
    .input-busca {
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: solid 1px var(--bordercolor);;
        border-radius: 5px;
        padding: 20px 30px;
        font-family: Montserrat;
        font-weight: normal;
        color: #81858e;
        font-size: 14px;
        transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
        position: absolute;
        right: 25px;
        top: 24px;
        width: 14.7px;
        height: 14.7px;
        z-index: 999;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg{
      path{
        stroke: var(--maincolor) !important
      }
    }
  }
  .gridListMenu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: -25px;
    position: absolute;
    right: 0;
    .menu{
      cursor: pointer;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        justify-items: start;
        color: var(--fontcolor);
        padding: 7px;
        transition: 0.5s;
        justify-self: center;
    }
  }
  .gridListMenuMobile{
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 25px;
    .menu{
      cursor: pointer;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        justify-items: start;
        color: var(--fontcolor);
        padding: 7px;
        transition: 0.5s;
    }
  }
  .paginate{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #333333;
      cursor: pointer;
    }
    .text:hover{
      color:#00E4A0;
    }
    .text:hover svg path{
      stroke:#00E4A0 !important
    }
  }
  .cancelaBtn{
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858E !important;
  }
  .btnResp {
    background: var(--maincolortrans);
    color: var(--maincolor);
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    padding: 12px 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  textarea {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 0.5px solid #ededf0;
    padding: 5px 0;
    padding-right: 20px;
    font-size: 13px;
    min-height: 50px;
    margin-top: 20px;
  }
  .hide{
    display: none;
  }
  .spaceTable{
    margin-top: 60px;
    .spaceInfo{
      cursor: pointer;
      .info{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858E;
        margin-bottom: 5px;
      }
    }
    .spaceComment{
      // width: 40em;
      .comment{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
      }
      .actions{
        line-height: 0px;
        .btns{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          border: none;
          background: none;
          margin-top: 30px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .aprove{
          color: #00E4A0;
        }
        .resp{
          color: #81858E;
        }
        .edit{
          color: #81858E;
        }
        .delete{
          color: #FF0C37;
        }
        .removeaprove{
          color: #dfbe06;
        }
      }
    }
    .spaceCreate {
      .dataCreate{
        display: flex;
        .userDados{
          .name{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: var(--fontcolor);
            margin-bottom: 10px;
          }
          .email{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #81858E;
            margin-bottom: 20px;
          }
        }
        .userPic{
          // background: rgb(0, 0, 0);
          // border: 1px solid #00D6E4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 20px;
          img{
            width: 100%;
            border-radius: 100%;
          }
        }
      }
      .date{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858E;
        margin-left: 60px;
      }
  }
    .table thead th {
      border: none !important;
    }
    .table th, .table td {
      border: none !important;
    }
    th{
      div{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
        margin-bottom: 40px;
      }
    }
  }
  .spaceActive{
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .headerFlex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color:var(--fontcolor);
    }
    .menu{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}

.spaceComunidade {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 70px;
  .textTotalSelected{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #81858E;
    margin-bottom: -40px;
    margin-top: 20px;
  }
  .espacamento-svg-action{
      margin-right: 5px;
      margin-bottom: 3px;
  }
  .btns-actions{
      border:none;
      border: 1px solid #00E4A0;
      box-sizing: border-box;
      border-radius: 3px;
      height: 38px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #00E4A0;
      margin-right: 20px;
      background-color: #fff0;
      width: 136px;
      margin-top: 30px;
      margin-bottom: -40px;
  }
  .spaceSearch{
    .inputSearch {
        position: relative;
        margin-top: 20px;
        width: 28em;
        top:10px;
        img{
          cursor: pointer;
        }
    }
    .input-busca {
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: solid 1px var(--bordercolor);;
        border-radius: 5px;
        padding: 20px 30px;
        font-family: Montserrat;
        font-weight: normal;
        color: #81858e;
        font-size: 14px;
        transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
        box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
        position: absolute;
        right: 25px;
        top: 24px;
        width: 14.7px;
        height: 14.7px;
        z-index: 999;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }
  }
  .activeList {
    color: var(--maincolor) !important;
    transition: 0.3s;
    background: #f9f9f92b;
    border-radius: 3px;
    border: 1px solid var(--maincolor);
    svg{
      path{
        stroke: var(--maincolor) !important
      }
    }
  }
  .gridListMenu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: -25px;
    position: absolute;
    right: 0;
    .menu{
      cursor: pointer;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        justify-items: start;
        color: var(--fontcolor);
        padding: 7px;
        transition: 0.5s;
        justify-self: center;
    }
  }
  .paginate{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-top: 40px;
    .text{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #333333;
      cursor: pointer;
    }
    .text:hover{
      color:#00E4A0;
    }
    .text:hover svg path{
      stroke:#00E4A0 !important
    }
  }
  .cancelaBtn{
    margin-right: 20px;
    background: #f7f7f7 !important;
    color: #81858E !important;
  }
  .btnResp {
    background: var(--maincolortrans);
    color: var(--maincolor);
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 3px;
    padding: 12px 25px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  textarea {
    display: block;
    width: 100%;
    color: var(--fontcolor);
    border: none;
    border-bottom: 0.5px solid #ededf0;
    padding: 5px 0;
    background: var(--backgroundcolor) !important;
    padding-right: 20px;
    font-size: 13px;
    min-height: 50px;
    margin-top: 20px;
  }
  .hide{
    display: none;
  }
  .spaceTable{
    margin-top: 60px;
    .spaceInfo{
      cursor: pointer;
      .info{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858E;
        margin-bottom: 5px;
      }
    }
    .spaceComment{
      width: 40em;
      .comment{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
      }
      .actions{
        display: flex;
        .btns{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          border: none;
          background: none;
          margin-top: 30px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .aprove{
          color: #00E4A0;
        }
        .resp{
          color: #81858E;
        }
        .edit{
          color: #81858E;
        }
        .delete{
          color: #FF0C37;
        }
        .removeaprove{
          color: #dfbe06;
        }
      }
    }
    .spaceCreate {
      margin-bottom: 40px;
      .dataCreate{
        display: flex;
        .userDados{
          .name{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: var(--fontcolor);
            margin-bottom: 10px;
          }
          .email{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #81858E;
            margin-bottom: 20px;
          }
        }
        .userPic{
          // background: rgb(0, 0, 0);
          // border: 1px solid #00D6E4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 20px;
          img{
            width: 100%;
            border-radius: 100%;
          }
        }
      }
      .date{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #81858E;
        margin-left: 60px;
      }
  }
    .table thead th {
      border: none !important;
    }
    .table th, .table td {
      border: none !important;
    }
    th{
      div{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: var(--fontcolor);
        margin-bottom: 40px;
      }
    }
  }
  .spaceActive{
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .headerFlex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color:var(--fontcolor);
    }
    .menu{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}
</style>
